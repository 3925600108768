<template>
  <div id="app">
  <!-- <mainHeader /> -->
    <SessionTimer />
    <div :class="{ 'loading-wrapper': on_load, invisible: load_done }">
      <div class="loading-wave">
        <div class="loading-bar" :style="clientColor"></div>
        <div class="loading-bar" :style="clientColor"></div>
        <div class="loading-bar" :style="clientColor"></div>
        <div class="loading-bar" :style="clientColor"></div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
// import MainHeader from "./components/MainHeader"

import SessionTimer from "@/components/SessionTimer"
import axios from "axios";
import { API_URL } from "./api_url"

export default {
  name: 'App',

  components: {
    // MainHeader,
    SessionTimer
  },
  data() {
    return {
      on_load: true,
      load_done: false
    };
  },
  computed: {
    clientColor() {
      const { background_color } = this.$store.state.auth.jobFlowClient ?? {};
      if (!background_color) {
        return {}; // Return an empty object if background_color is not available
      }
      return {
        'backgroundColor': background_color
      };
    }
  },
  mounted() {
    this.on_load = true;
    this.load_done = false;

    if (!this.$store.state.auth.sessionId) {
      this.jwtLogin()
    } else {
      this.get_templates()
    }

    window.addEventListener("resize", this.send_height_to_parent_function); // whenever the page is resized
    window.addEventListener("readystatechange", this.send_height_to_parent_function); // whenever the page is resized
    var observer = new MutationObserver(this.send_height_to_parent_function); // whenever DOM changes PT1
    var config = { attributes: true, childList: true, characterData: true, subtree: true }; // PT2
    observer.observe(window.document, config); // PT3
  },
  methods: {
    async jwtLogin() {
      this.$store.dispatch("auth/login").then(
        () => { this.get_templates() },
        (err) => { }
      );
    },
    get_templates() {
      console.log("getting templates")
      axios.get(`https://${API_URL}/v1/templates`, { headers: { 'Authorization': this.$store.state.auth.sessionId } })
        .then(response => {
          this.$store.state.app.templates = [...response.data].slice(0, 500);
          this.on_load = false;
          this.load_done = true;
        })
        .catch(err => {
          this.jwtLogin()
        });
    },
    send_height_to_parent_function() {
      var element = document.querySelector("body");
      var height = element.offsetHeight;
      // console.log("Sending height as " + height + "px");
      parent.postMessage({ "height": height + 600 }, "*");
    },
    resizeIframe(iframe) {
      this.resizeItNowPrivate(iframe);
    },

    resizeIframeOnload() {
      let iframe = document.getElementById('healthy_behavior_iframe');
      iframe.onload = () => {
        this.resizeItNowPrivate(iframe);
      }
    },

    resizeItNowPrivate() {
      let iframe = document.getElementById('healthy_behavior_iframe');
      if (!iframe) return;
      // console.log("there's an iFrame")
      var body = iframe.contentWindow.document,
        html = iframe.contentWindow.document.documentElement;
      var height = Math.max(body.scrollHeight ?? 0, body.offsetHeight ?? 0,
        html.clientHeight ?? 0, html.scrollHeight ?? 0, html.offsetHeight ?? 0);
      if (height < 600) {
        height = 600;
      }
      iframe.style.height = height + 'px';
    }
  }
}
</script>
