<template>
    <div class="wrapper">

        <nav class="navbar navbar-expand">
            <ul class="navbar-nav mr-auto px-0">
                <li class="nav-item">
                    <router-link :style="clientColor" v-if="currentRouteName !== 'create'" to="/"
                        class="nav-link p-button mpp-btn">zurück</router-link>
                </li>
            </ul>
        </nav>
        <div class="container">



            <section id="tc-modalDescription" class="tc-modal-body p-fluid">
                <header id="tc-modalTitle" class="mpp-modal-header tc-modal-header">
                    <slot class="header">
                        <span class="mpp-modal-headline">Neues Template</span>
                    </slot>

                </header>
                <form class="mpp-modal-form" style="height: 65%" @submit.prevent="createTemplate()">
                    <div class="form-group">
                        <label class="font-weight-bold">Template Name*</label>
                        <input v-model="name" type="text" class="mpp-input form-control p-inputtext" placeholder=""
                            required="required">&nbsp;
                        <label class="font-weight-bold">Sender</label>
                        <input :value="getMailAddress()" type="text" class="mpp-input form-control p-inputtext" placeholder=""
                            disabled>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">Subject*</label>
                        <input v-model="subject" type="text" class="mpp-input form-control p-inputtext" placeholder=""
                            required="required">&nbsp;
                        <label class="font-weight-bold">Sender-Name</label>
                        <input v-model="senderName" type="text" class="mpp-input form-control p-inputtext" placeholder="">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold">HTML-Code</label>
                        <prism-editor v-model="html" class="mpp-input mpp-input-editor my-editor height-300 p-inputtext" :highlight="highlighter"
                            line-numbers />
                    </div>
                    <footer class="tc-modal-footer">
                        <input type="submit" class="mpp-btn btn btn-default" :style="clientColor" value="Create Template">
                    </footer>
                </form>
            </section>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../api_url";
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {
    name: 'MailDetailModal',

    components: {
        PrismEditor
    },

    emits: ['close'],


    data() {
        return {
            on_load: false,
            load_done: true,
            html: '',
            name: '',
            subject: '',
            senderName: '',
            mailObj: {}
        };
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        clientColor() {
            const { background_color, text_color } = this.$store.state.auth.jobFlowClient ?? {};

            if (!background_color) {
                return {}; // Return an empty object if background_color is not available
            }

            return {
                'backgroundColor': background_color,
                'color': text_color,
                '--client-color-hover': `rgba(${this.extractRGB(background_color)}, 0.6)`
            };
        },
    },
    methods: {

        highlighter(code) {
            return highlight(code, languages.markup); // languages.<insert language> to return html with markup
        },

        close(updateTable) {
            [this.html, this.name, this.subject, this.senderName] = '';
            this.$emit('close', updateTable ?? false);
        },
        getMailDomain() {
            return this.$store.state.auth.mailDomain;
        },
        getMailAddress() {
            return this.$store.state.auth.client + '@' + this.$store.state.auth.mailDomain;
        },
        createTemplate() {
            this.on_load = true;
            this.load_done = false;
            var sendObj = {
                'name': this.name,
                'subject': this.subject,
                'senderName': this.senderName,
                'text': '',
                'html': this.html
            }
            axios.post(`https://${API_URL}/v1/template/create`, sendObj, { headers: { 'Authorization': this.$store.state.auth.sessionId } })
                .then(response => {
                    this.mailObj = response.data;
                    this.close(true);
                    this.load_done = true;
                    this.$router.push('/');
                })
        },
        extractRGB(color) {
            const hex = color.replace('#', '');
            const r = parseInt(hex.substr(0, 2), 16);
            const g = parseInt(hex.substr(2, 2), 16);
            const b = parseInt(hex.substr(4, 2), 16);
            return `${r}, ${g}, ${b}`;
        },
    }
}


</script>

<style lang="scss">
/* required class */

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}

.height-300 {
    height: 300px;
}

.tc-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.03);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tc-modal {
    position: relative;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    width: 40%;
    height: 75%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.inactive {
    display: none;
}

.active {
    display: block;
}

.tc-modal-header,
.tc-modal-footer {
    padding: 15px;
    display: flex;
}

.tc-modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #333333;
    justify-content: space-between;
}

.tc-modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    float: bottom;
}

.tc-modal-body {
    position: relative;
    padding: 20px 10px;
    height: 80%;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #aaaaaa;
    background: transparent;
}

.tc-modal-fade-enter,
.tc-modal-fade-leave-to {
    opacity: 0;
}

.tc-modal-fade-enter-active,
.tc-modal-fade-leave-active {
    transition: opacity .5s ease;
}

.font-weight-bold {
    font-weight: bold;
}

.textarea {
    width: 100%;
    height: 90%;
}

.mc-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mc-modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    width: 40%;
    height: 75%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.mc-modal-header,
.mc-modal-footer {
    padding: 15px;
    display: flex;
}

.mc-modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #333333;
    justify-content: space-between;
}

.mc-modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    float: bottom;
}

.mc-modal-body {
    position: relative;
    padding: 20px 10px;
    height: 80%;
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #aaaaaa;
    background: transparent;
}

.btn-green {
    color: white;
    background: #aaaaaa;
    /* border: 1px solid #000000;
   border-radius: 3px; */
}

.btn-default {
    color: white;
    background: #aaaaaa;
    border: 1px solid #000000;
    /* border-radius: 3px; */
    float: left;
    width: 45%;
    margin-right: 20%;
}

.form-control {
    /* border: 1px solid #000000; */
    border-radius: 3px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
    padding: 10px;
    margin-bottom: 1.8rem;

}

.mc-modal-fade-enter,
.mc-modal-fade-leave-to {
    opacity: 0;
}

.mc-modal-fade-enter-active,
.mc-modal-fade-leave-active {
    transition: opacity .5s ease;
}

.btn {
    float: right;

    &:hover,
    &:active,
    &:focus {
        color: #000 !important;
        background-color: var(--client-color-hover) !important;

    }
}
</style>
