<template>
  <div class="wrapper">
    <nav class="navbar navbar-expand">
        <ul class="navbar-nav mr-auto px-0">
          <li class="nav-item">
            <router-link :style="clientColor" v-if="currentRouteName !== 'settings'" to="/"
              class="nav-link p-button">zurück</router-link>
          </li>
        </ul>
      </nav>
    <div class="container">

      <section id="tc-modalDescription" class="tc-modal-body p-fluid">
        <header id="tc-modalTitle" class="tc-modal-header">
          <slot name="header">
            <span class="font-weight-bold">Update Template</span>
          </slot>
          <!-- <button type="button" class="btn-close" aria-label="Close modal" @click="close">
            x
          </button> -->
        </header>
        <form style="height: 65%" @submit.prevent="createTemplate()">


          <div class="form-group">
            <label for="description">Template-Name*</label>
            <input v-model="template_name" class="form-control p-inputtext p-component" id="description"
              name="description" type="text" placeholder="" required="required">&nbsp;
          </div>
          <div class="form-group">
            <label>Template-Name*</label>
            <input v-model="template_name" type="text" class="form-control p-inputtext" placeholder=""
              required="required">&nbsp;
            <label>Sender</label>
            <input :value="getMailAddress()" type="text" class="form-control p-inputtext" placeholder="" disabled>
          </div>
          <div class="form-group">
            <label>Subject*</label>
            <input v-model="template_subject" type="text" class="form-control p-inputtext" placeholder=""
              required="required">&nbsp;
            <label>Sender-Name</label>
            <input v-model="template_sender_name" type="text" class="form-control p-inputtext" placeholder="">
          </div>
          <div class="form-group">
            <label>HTML-Code</label>
            <prism-editor v-model="template_html" class="my-editor height-300 p-inputtext" :highlight="highlighter"
              line-numbers />
          </div>
          <footer class="tc-modal-footer">
            <input type="submit" class="btn btn-default" :style="clientColor" value="Save Template">
            <!-- <button type="button" class="btn btn-default" aria-label="Close modal" @click="close" :style="clientColor">
          Close
        </button> -->
          </footer>

        </form>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../api_url"

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

export default {



  props: ['data'],

  name: 'BasicSettings',
  components: { PrismEditor },
  data() {
    return {
      result: {},
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    clientColor() {
      const { background_color, text_color } = this.$store.state.auth.jobFlowClient ?? {};

      if (!background_color) {
        return {}; // Return an empty object if background_color is not available
      }

      return {
        'backgroundColor': background_color,
        'color': text_color,
        '--client-color-hover': `rgba(${this.extractRGB(background_color)}, 0.6)`
      };
    },
    template_id() {
      return this.$store.state.app.current_template.id
    },
    template_name: {
      get: function () {
        return this.$store.state.app.current_template.template_name
      },
      set: function (newValue) {
        console.log(newValue);
        return this.$store.state.app.current_template.template_name = newValue;
      }
    },
    template_subject: {
      get: function () {
        return this.$store.state.app.current_template.subject
      },
      set: function (newValue) {
        console.log(newValue);
        return this.$store.state.app.current_template.subject = newValue;
      }
    },
    template_sender_name: {
      get: function () {
        return this.$store.state.app.current_template.sender_name
      },
      set: function (newValue) {
        return this.$store.state.app.current_template.sender_name = newValue;
      }
    },
    template_text: {
      get: function () {
        return this.$store.state.app.current_template.plain_text
      },
      set: function (newValue) {
        console.log(newValue);
        return this.$store.state.app.current_template.plain_text = newValue;
      }
    },
    template_html: {
      get: function () {
        console.log("template_html: ", this.$store.state.app.current_template.html);
        return this.$store.state.app.current_template.html
      },
      set: function (newValue) {
        console.log(newValue);
        this.$store.state.app.current_template.html = newValue;
      }
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.markup); // languages.<insert language> to return html with markup
    },

    close(updateTable) {
      this.$emit('close', updateTable ?? false);
    },
    getMailAddress() {
      return this.$store.state.auth.client + '@' + this.$store.state.auth.mailDomain;
    },
    createTemplate() {
      var sendObj = {
        'id': this.template_id,
        'name': this.template_name,
        'subject': this.template_subject,
        'sender_name': this.template_sender_name,
        'text': this.template_text,
        'html': this.template_html
      };

      axios.put(`https://${API_URL}/v1/template/${this.template_id}`, sendObj, { headers: { 'Authorization': this.$store.state.auth.sessionId } })
        .then(response => {
          this.result = response.data;
          this.close(true);
        })
    },

    extractRGB(color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      return `${r}, ${g}, ${b}`;
    },
  }
}

</script>

<style lang="scss">
.wrapper {
  display: inline;
}



.navbar-expand .navbar-nav .nav-link {
  text-transform: uppercase;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-weight: 700;
}

nav {
  display: block;

  .navbar-nav {
    list-style: none;
  }

  .nav-link {
    box-shadow: 1px 1px 3px rgb(97 94 94 / 25%);
    padding: 10px 20px;
    display: inline-block;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;
    margin-right: 10px;

    &:hover,
    &:active,
    &:focus {
      color: #333333;
    }

    &.router-link-active {
      background: #e6e6e6;
    }
  }
}

.tc-modal-backdrop {
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tc-modal {
  background: #f5f5f5;
  box-shadow: 2px 2px 20px 1px;
  width: 40%;
  height: 75%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.inactive {
  display: none;
}

.active {
  display: block;
}

.tc-modal-header {
  padding: 15px;
  display: flex;
  align-items: flex-end;
}

.tc-modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #333333;
  justify-content: space-between;
}

.tc-modal-footer {
  padding: 15px;
  display: flex;
  align-items: flex-end;
}



.tc-modal-footer {
  /* border-top: 1px solid #eeeeee; */
  flex-direction: column;
  float: bottom;
}

.tc-modal-body {
  position: relative;
  padding: 20px 10px;
  height: 80%;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #aaaaaa;
  background: transparent;
}

.btn-green {
  color: white;
  background: #aaaaaa;
  /* border: 1px solid #000000;
   border-radius: 3px; */
}

.tc-modal-fade-enter,
.tc-modal-fade-leave-to {
  opacity: 0;
}

.tc-modal-fade-enter-active,
.tc-modal-fade-leave-active {
  transition: opacity .5s ease;
}

.font-weight-bold {
  font-weight: bold;
}

.textarea {
  width: 100%;
  height: 90%;
}

.btn {
  float: right;

  &:hover,
  &:active,
  &:focus {
    color: #000 !important;
    background-color: var(--client-color-hover) !important;

  }
}
</style>
