import { createRouter, createWebHashHistory } from 'vue-router'
import SettingsView from '../views/SettingsView.vue'
import TemplateCreate from '../views/TemplateCreate.vue'

const routes = [
    { path: '/', 
    component: () => import('../components/Home.vue') },
    {
        path: '/settings/:data',
        name: 'Settings',
        component: SettingsView,
        props: true

    },
    {
        path: '/create/',
        name: 'TemplateCreate',
        component: TemplateCreate

    },
    { path: '/templates/default', name: 'default_templates', component: () => import('../components/DefaultTemplates.vue') },
    { path: '/ruleset/:rulesetId/settings', name: 'RulesetSettings', component: () => import('../components/RulesetSettings.vue') }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
