import AuthService from '../services/auth.service';

const initialState = {
  sessionId: '',
  jobFlowClient: null,
  mailDomain: '',
  client: null,
  user: null,
  sessionTimeout: null
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    logout({ commit }) {
      commit('logout');
    },
    login({ commit }) {
      commit('logout');
      return AuthService.auth().then(
        data => {
          commit('login', { sessionId: data.sessionID, mailDomain: data.mail_domain });
          commit('setJobFlowClient', data.jobflow?.client);
          commit('setClient', data.client);
          commit('setSessionTimer', data.sessionTimeout ?? (Math.floor(Date.now() / 1000) + 3600));
          return Promise.resolve(data);
        },
        error => {
          commit('logout');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    login(state, { sessionId, mailDomain }) {
      state.sessionId = sessionId;
      state.mailDomain = mailDomain;
      state.sessionTimeout = null;
    },
    logout(state) {
      state.sessionId = '';
      state.user = null;
    },
    setClient(state, client) {
      state.client = client;
    },
    setJobFlowClient(state, client) {
      state.jobFlowClient = client;
    },
    setSessionTimer(state, timeout) {
      state.sessionTimeout = timeout;
    }
  }
};
